import Vue from 'vue'
import Vuex from 'vuex'

import forms from '../store/modules/app/forms'


Vue.use(Vuex)

export default new Vuex.Store({
   modules: {
     forms
   }
 })