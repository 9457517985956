import axios from 'axios'
import Vue from 'vue'
import i18n from '../../../i18n';

const state = {
    message: {},
    loading: true
}

const getters = {
   getResponseMessage: state => {
    return state.message;
}
}

const mutations = {
    updateResponseMessage(state, message) {
      state.message = message
    },
    changeLoadingState(state, loading) {
      state.loading = loading
    },
  }

const actions = {
  ['FORM_REQUEST']: ({commit}, data) => {
    return new Promise((resolve) => { // The Promise used for router redirect in login
    axios.post('combme/WS_SendEmailFromWeb', {
      name: data.name,
      email: data.email,
      body: data.body,
    })
    .then(resp => {
        if(!resp.data.error){
            Vue.toasted.show(i18n.tc(`messageSuccess`),{
              theme: 'outline',
              singleton: true,
              position:'bottom-center',
              action :
              {
                  text : i18n.tc(`ok`),
                  onClick : (e, toastObject) => {
                      toastObject.goAway(0);
                  }
                  
              }
          });
            commit('updateResponseMessage', resp.data.message)
            commit('changeLoadingState', false)
            } else {
              Vue.toasted.show(i18n.tc(`messageFailed`),{
                theme: 'outline',
                singleton: true,
                position:'bottom-center',
                action :
                {
                    text : i18n.tc(`ok`),
                    onClick : (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                    
                }
            });
        }
        resolve(resp)
        })
    })
  },
}


export default {
    state,
    getters,
    mutations,
    actions
}