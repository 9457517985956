import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from './../store/store'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', redirect: `/${i18n.locale}/home`,
  },
  {
    path: '/tc-de', 
    name: 'eula de',
    component: () => import(/* webpackChunkName: "EulaDe" */ '../components/eula/EulaDe.vue')
  },
  {
    path: '/tc-en', 
    name: 'eula en',
    component: () => import(/* webpackChunkName: "EulaEn" */ '../components/eula/EulaEn.vue')
  },
  {
    path: '/pp-de', 
    name: 'pp eula de',
    component: () => import(/* webpackChunkName: "PrivacyPolicyEulaDe" */ '../components/eula/PrivacyPolicyEulaDe.vue')
  },
  {
    path: '/pp-en', 
    name: 'pp eula en',
    component: () => import(/* webpackChunkName: "PrivacyPolicyEulaEn" */ '../components/eula/PrivacyPolicyEulaEn.vue')
  },
  {
    path: '/:lang',
    component:{
      render (c) {return c('router-view')}
    },
      children: [
        {
          path: 'home', 
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
        },
        {
          path: 'privacy-policy', 
          name: 'privacy policy',
          component: () => import(/* webpackChunkName: "home" */ '../components/PrivacyPolicy.vue')
        },
        {
          path: 'imprint', 
          name: 'imprint',
          component: () => import(/* webpackChunkName: "home" */ '../components/Imprint.vue')
        },
      ],
  },
  { path: '/*', 
    component: () => import('../components/PageNotFound.vue') 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
