import Vue from 'vue'
import App from './App.vue'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueRouter from 'vue-router'
import router from './router'
import Vuelidate from 'vuelidate'
import store from './store/store'
import Toasted from 'vue-toasted';
// import $ from 'jquery'

import 'bootstrap/dist/css/bootstrap.min.css'

import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.common['language'] = 'EN',
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8'


Vue.prototype.$http = axios

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(axios)
Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(Toasted, {singleton: true,
                  position:'top-center',
                  theme: 'outline',})
// Vue.use($)
//import global less file
// require('./assets/less/main.less');
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
